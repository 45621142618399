@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	[multiple],
	[type="date"],
	[type="datetime-local"],
	[type="email"],
	[type="month"],
	[type="number"],
	[type="password"],
	[type="search"],
	[type="tel"],
	[type="text"],
	[type="time"],
	[type="url"],
	[type="week"],
	select,
	textarea {
		@apply border-0;
	}
}

*:focus {
	outline: none;
}

.container {
	@apply w-full max-w-[1300px] px-5 lg:px-12 min-[1440px]:px-0;
}

.container-first-section {
	@apply ml-auto w-[95.1388888889%];
}

.btn {
	@apply h-10 w-fit flex-shrink-0 items-center justify-center rounded border border-solid border-orange px-7 text-base font-medium text-white transition-colors hover:bg-orange hover:text-black;

	&.btn-primary {
		@apply bg-orange text-black hover:bg-orange/20 hover:text-orange;
	}

	&.btn-shadow {
		@apply bg-black/50 px-4 hover:bg-orange hover:text-black;
	}
}

.gradient-corner {
	@apply relative before:absolute before:left-0 before:bottom-0 before:h-full before:w-px before:bg-gradient-to-t before:from-white before:to-transparent after:absolute after:bottom-0 after:left-0 after:h-px after:w-full after:max-w-[9.5rem] after:bg-gradient-to-r after:from-white after:to-transparent;
}
